<script>
import Swal from "sweetalert2";

export default {
    name: 'address-card',

    props: {
        address: {
            type: Object,
            required: true
        }
    },

    computed: {
        addressType: function () {
            return this.address && this.address.address_type
                ? this.address.address_type.translation_key ? this.$t(this.address.address_type.translation_key).ucFirst() : this.address.address_type.name
                : ''
        },
    },

    methods: {
        select: function () {
            this.$store.dispatch('address/select', this.address.id)
        },

        remove: function () {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('address/destroy', this.address.id)
                }
            });
        },
    }
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="dropdown float-end">
                <a
                    aria-haspopup="true"
                    class="text-muted dropdown-toggle font-size-16"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                >
                    <i class="bx bx-dots-horizontal-rounded"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#" @click="select">{{ $t('base.edit').ucFirst() }}</a>
                    <a class="dropdown-item" href="#" @click="remove">{{ $t('base.delete').ucFirst() }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div class="flex-1 ms-3">
                    <h5 class="font-size-16 mb-1">
                        <span class="text-dark">{{ address.name }}</span>
                    </h5>
                    <span class="badge badge-soft-success mb-0"
                    >{{ addressType }}</span
                    >
                    <p class="mt-2">
                        <small>{{ address.address }}</small>
                    </p>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
