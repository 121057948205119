<script>
import {mapGetters} from "vuex";
import AddressForm from "../../../components/forms/address-form.vue";
import AddressCard from "../../../components/elements/address-card.vue";

export default {
    components: {AddressCard, AddressForm},

    computed: {
        ...mapGetters({
            address: 'address/item',
            addresses: 'address/list'
        }),

        showForm: function () {
            return this.address.id || this.create
        }
    },

    data: function () {
        return {
            create: false,
            height: '100%'
        }
    },

    methods: {
        setHeight: function () {
            if (this.height === '100%') {
                this.height = this.$refs.trans.clientHeight + 'px';
            }
        },

        emptyAddress: function () {
            this.$store.dispatch('address/clearItem')
            this.create = false
        },
    },

    created() {
        this.$store.dispatch('address/list', {partner_id: this.$route.params.id, with: 'addressType'})
    }
}
</script>

<template>
    <div>
        <div class="row mb-4">
            <div class="col-12 text-start">
                <b-button variant="primary" @click="create = !create">{{ $t('base.create').ucFirst() }}</b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12 py-3 rounded mb-4" :class="{'bg-light': showForm}" ref="trans">
                <transition type="animation" @enter="setHeight" @after-leave="height = '100%'">
                    <address-form v-show="showForm" :address="address" @saved="emptyAddress"></address-form>
                </transition>
            </div>
            <div v-if="addresses && addresses.length" class="col-12">
                <div class="row">
                    <div v-for="(add, index) in addresses" :key="index" class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <address-card :address="add"></address-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

@keyframes slide-left {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
    100% {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

/* we will explain what these classes do next! */
.v-enter-from {
    width: 0;
    height: 0;
    overflow: hidden;
}

.v-enter-active {
    animation: slide-left 0.5s ease-in;
    overflow: hidden;
}

.v-leave-active {
    animation: slide-right 0.5s ease-in;
    overflow: hidden;
}
</style>
